import { Button, Container, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import ConfirmationScreen from "../../../../assets/components/ConfirmationScreen";
import TabTopBar from "../../../../assets/components/TabTopBar";
import TableToolbar from "../TableToolbar";
import { ExternalData } from "./ExternalData";
import ExternalModal from "./ExternalModal";
import ExternalTable from "./ExternalTable";
import {
  CustomButton,
  CustomTextField,
} from "../../../../components/custom_components";

export default function ExternalOverview(props) {
  const { external, newExternal, deleteExternal, editExternal, fetchExternal } =
    ExternalData(props);

  const default_modal = { open: false, callback: null, external: null };

  const [search, setSearch] = useState("");
  const [modal, setModal] = useState(default_modal);
  const [confirm_modal, setConfirmModal] = useState(false);
  const [selected, setSelected] = useState([]);

  const selectExternal = (arg) => {
    if (arg === "all") setSelected(external.map((inst) => inst.id));
    else if (arg === "none") setSelected([]);
    else {
      var index = selected.indexOf(arg),
        new_selected = [...selected];
      index >= 0 ? new_selected.splice(index, 1) : new_selected.push(arg);
      setSelected(new_selected);
    }
  };

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        p: 2,
        height: `calc(100% - ${20}px)`,
        overflow: "auto",
        backgroundColor: "#f5f7f9",
      }}
    >
      <TabTopBar title="Externen" subtitle="Beheer externen">
        <Stack direction="row" spacing={1} alignItems="center">
          <CustomButton
            onClick={() => setConfirmModal(true)}
            size="small"
            variant="contained"
          >
            Verwijderen
          </CustomButton>
          <CustomButton
            color="primary"
            variant="contained"
            size="small"
            onClick={() =>
              setModal({
                open: true,
                callback: newExternal.bind(this),
                installer: null,
              })
            }
          >
            Nieuw
          </CustomButton>
          <CustomTextField
            placeholder="Zoeken..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Stack>
      </TabTopBar>
      <ExternalTable
        data={external}
        search={search}
        selected={selected}
        editExternal={(inst) =>
          setModal({
            open: true,
            callback: editExternal.bind(this),
            external: inst,
          })
        }
        selectAll={() => selectExternal("all")}
        deselectAll={() => selectExternal("none")}
        editClient={(id) => selectExternal(id)}
      />
      {modal.open && (
        <ExternalModal
          open={modal.open}
          close={() => setModal(default_modal)}
          external={modal.external}
          fetchExternal={fetchExternal.bind(this)}
          textAlign="left"
          confirm_button="Opslaan"
          saveExternal={(data, cb, ecb) => modal.callback(data, cb, ecb)}
        />
      )}
      {confirm_modal && (
        <ConfirmationScreen
          open={confirm_modal}
          close={() => setConfirmModal(false)}
          textAlign="left"
          confirm_button="Bevestig"
          confirm={() =>
            deleteExternal(selected, () => {
              fetchExternal();
              setConfirmModal(false);
            })
          }
        >
          Weet je zeker dat je deze installateurs wil verwijderen?
        </ConfirmationScreen>
      )}
    </Container>
  );
}
