import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Stack,
  TextField,
} from "@mui/material";
import { failedNotification } from "../../../../toolbox/notification-center";
import {
  CustomButton,
  CustomTextField,
} from "../../../../components/custom_components";

export default function ExternalModal(props) {
  const [open, setOpen] = useState(false);

  const [init, setInit] = useState(false);

  const [name, setName] = useState("");
  const [contact_person, setContactPerson] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [location, setLocation] = useState("");
  const [phone, setPhone] = useState("");
  const [rol, setRol] = useState("");

  useEffect(() => {
    if (!init && "external" in props && props.external !== null) {
      setContactPerson(props?.external?.contact_person ?? "");
      setName(props?.external?.name ?? "");
      setEmail(props?.external?.email ?? "");
      setLocation(props?.external?.location ?? "");
      setPhone(props?.external?.phone_number ?? "");
      setRol(props?.external?.rol ?? "");
    }
  }, [props.external]);

  const saveExternal = () => {
    if (props.external !== null) {
      if (
        contact_person !== "" &&
        name !== "" &&
        email !== "" &&
        location !== "" &&
        phone !== "" &&
        rol !== ""
      ) {
        var args = {
          id: props?.external?.id ?? "",
          name: name,
          contact_person: contact_person,
          email: email,
          location: location,
          phone_number: phone,
          rol: rol,
        };
        if (password !== "") args.password = password;
        props.saveExternal(args, () => {
          props.fetchExternal();
          props.close();
        });
      } else {
        failedNotification(
          "Missende info",
          "Zorg dat alle velden zijn ingevuld."
        );
      }
    } else {
      if (
        contact_person !== "" &&
        name !== "" &&
        email !== "" &&
        password !== "" &&
        location !== "" &&
        phone !== "" &&
        rol !== ""
      ) {
        props.saveExternal(
          {
            name: name,
            contact_person: contact_person,
            email: email,
            password: password,
            location: location,
            phone_number: phone,
            rol: rol,
          },
          () => {
            props.fetchExternal();
            props.close();
          },
          (message) => {
            console.log(message);
          }
        );
      } else {
        failedNotification(
          "Missende info",
          "Zorg dat alle velden zijn ingevuld."
        );
      }
    }
  };

  useEffect(() => {
    if (props.open && !open) {
      setOpen(true);
    }
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.close(), 200);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <CustomTextField
            placeholder={"Naam"}
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <CustomTextField
            placeholder={"Contactpersoon"}
            id="contact_person"
            value={contact_person}
            onChange={(e) => setContactPerson(e.target.value)}
          />
          <CustomTextField
            placeholder={"Email"}
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <CustomTextField
            placeholder={"Wachtwoord"}
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <CustomTextField
            placeholder={"Stad"}
            id="location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
          <CustomTextField
            placeholder={"Telefoon"}
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <CustomTextField
            placeholder={"Rol"}
            id="rol"
            value={rol}
            onChange={(e) => setRol(e.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <CustomButton color="error" onClick={handleClose} size="small">
          Annuleren
        </CustomButton>
        <CustomButton color="primary" onClick={saveExternal} size="small">
          Opslaan
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}
