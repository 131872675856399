import { Container, FormControlLabel, Stack, Switch } from "@mui/material";
import SystemsModel from "./services/systemsModel";
import TabTopBar from "../../assets/components/TabTopBar";
import {
  CustomTable,
  CustomTextField,
} from "../../components/custom_components";
import { SystemsColumns } from "./SystemsColumns";
import { store_session } from "../../toolbox/local_storage";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

function Systems() {
  const {
    systems,
    count,
    loading,
    searchParams,
    setSearchParams,
    page,
    setPage,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    toggleHide,
    hidden,
    setHidden,
  } = SystemsModel();

  const navigate = useNavigate();

  const sortClick = (key) => {
    if (key === orderBy && order === "asc") {
      setOrder("desc");
      store_session("systems_sort", { key: key, dir: "desc" });
    } else {
      setOrderBy(key);
      setOrder("asc");
      store_session("systems_sort", { key: key, dir: "asc" });
    }
  };

  const userInfo = useSelector((state) => state?.user_info ?? {});

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        minHeight: `calc(90vh - ${20}px)`,
        overflow: "auto",
        backgroundColor: "#f5f7f9",
        p: 2,
      }}
    >
      <TabTopBar title="Systemen" />
      <CustomTable
        data={systems}
        columns={SystemsColumns}
        loading={loading}
        count={count}
        page={page}
        height="65vh"
        setPage={setPage}
        renderProps={{
          installers_dict: {},
          order,
          setOrder,
          orderBy,
          setOrderBy,
          chartData: (client_id) =>
            navigate(
              `/${userInfo?.user_type ?? "admin"}/systemen-nieuw/${client_id}`
            ),
          showUserInfo: (client_id) =>
            window.open("/systeem-status#" + client_id, "_blank"),
          toggleHide,
          sortClick,
          isAdmin: (userInfo?.user_type ?? "") === "admin",
        }}
        labelRenderProps={{
          sortClick,
          orderBy,
          order,
        }}
        childrenRight={
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={hidden}
                  onChange={() => setHidden((old) => !old)}
                  name="checkedB"
                  color="primary"
                />
              }
              labelPlacement="start"
              label="Verborgen systemen"
            />
            <CustomTextField
              value={searchParams.get("search") ?? ""}
              onChange={(e) =>
                setSearchParams(
                  (prev) => {
                    prev.set("search", e.target.value);
                    return prev;
                  },
                  { replace: true }
                )
              }
              placeholder="Zoeken..."
            />
          </Stack>
        }
      />
    </Container>
  );
}

export default Systems;
