import React from "react";
import {
  Box,
  Collapse,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import LoadingOverlay from "../global/LoadingOverlay";
import CenteredText from "../CenteredText";
import UnderlinedTitle from "../UnderlinedTitle";
import useBreakpoint from "../../hooks/useBreakpoint";
import CustomIconButton from "./CustomIconButton";

/**
 *
 * @param {*} data - data to be displayed in the table. Is expected to be a list of dicts
 * @param {*} columns - columns to be displayed in the table. Is expected to be a list of dicts with the following keys: id, label, minWidth, align, render
 * @param {*} renderProps - props that are passed to the render function of each column
 * @param {*} subRenderProps - props that are passed to the render function of each subcolumn
 * @param {*} labelRenderProps - props that are passed to the label function of each column (OPTIONAL, MOSTLY USE THE LABEL INSTEAD)
 * @param {*} childrenLeft - (optional) children to be displayed on the top left of the table
 * @param {*} childrenRight - (optional) children to be displayed on the top right of the table
 * @param {*} paperProps - (optional) props to be passed to the Paper component
 * @param {*} page - (optional) page number of the table
 * @param {*} setPage - (optional) function to set the page number of the table
 * @param {*} count - (optional) total number of items in the table
 * @param {*} stickyHeader - (optional) whether the table header should be sticky
 * @param {*} subKey - (optional) key of the subtable
 * @param {*} loading - (optional) whether the table should display a loading overlay
 * @param {*} loadingSize - (optional) size of the loading overlay
 * @returns
 */

function CustomTable({
  data = [],
  columns = [],
  paperProps,
  renderProps,
  labelRenderProps,
  cellClickProps,
  childrenLeft,
  childrenRight,
  page,
  setPage,
  pageAmount = 20,
  count,
  stickyHeader,
  subKey,
  loading = false,
  loadingSize,
  title,
  height = "75vh",
  emptyText = "No results",
  children = null,
}) {
  const [subOpen, setSubOpen] = useState("");
  const getFlex = () => {
    if ((childrenLeft || title) && childrenRight) return "space-between";
    if (childrenLeft || title) return "flex-start";
    if (childrenRight) return "flex-end";
  };

  const { higher, lower } = useBreakpoint();

  return (
    <Paper
      elevation={0}
      sx={{
        border: "1px solid #DBDBDB",
        ...(paperProps?.sx ?? {}),
        position: "relative",
      }}
    >
      {loading && <LoadingOverlay size={loadingSize ?? "md"} />}
      {!loading && (data ?? []).length <= 0 && (
        <CenteredText>{emptyText}</CenteredText>
      )}
      <Box sx={{ px: 4, pb: 4, pt: 3 }}>
        {((childrenLeft ?? null) !== null ||
          (title ?? null) !== null ||
          (childrenRight ?? null) !== null) && (
          <Stack direction="row" justifyContent={getFlex()} sx={{ mb: 4 }}>
            {(title ?? null) !== null ? (
              <UnderlinedTitle title={title} />
            ) : (
              childrenLeft ?? <></>
            )}
            {childrenRight ?? <></>}
          </Stack>
        )}

        <TableContainer sx={{ height }}>
          <Table
            sx={{ borderSpacing: "4px 12px", borderCollapse: "separate" }}
            stickyHeader={stickyHeader ?? false}
          >
            <TableHead>
              <TableRow>
                {columns.map((col, i) => {
                  if (
                    ("showFrom" in col && !lower.includes(col.showFrom)) ||
                    ("showUntil" in col && !higher.includes(col.showUntil))
                  )
                    return null;
                  return (
                    <CustomHeadCell
                      key={`h_col_${col?.label}_${i}`}
                      align={col?.align ?? "left"}
                    >
                      {col?.renderLabel
                        ? col?.label({ ...labelRenderProps })
                        : col?.label}
                    </CustomHeadCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody sx={{ position: "relative" }}>
              {(loading ?? false ? [] : data ?? []).map((row, i) => (
                <React.Fragment key={`row_${i}`}>
                  <TableRow
                    sx={{
                      backgroundColor: "#F7F8FC",
                      borderRadius: 4,
                    }}
                  >
                    {columns.map((col, j) => {
                      if (
                        ("showFrom" in col && !lower.includes(col.showFrom)) ||
                        ("showUntil" in col && !higher.includes(col.showUntil))
                      )
                        return null;
                      return (
                        <CustomTableCell
                          key={`cell_${i}_${j}`}
                          align={col?.align ?? "left"}
                          hasSubOpen={subOpen === row[subKey]}
                          width={col?.width}
                          onClick={
                            typeof col?.cellOnClick === "undefined"
                              ? null
                              : () =>
                                  col?.cellOnClick?.({
                                    row,
                                    index: i,
                                    ...(cellClickProps ?? {}),
                                    ...(renderProps ?? {}),
                                  })
                          }
                        >
                          {col.render({
                            row,
                            index: i,
                            onSubClick: (row) =>
                              setSubOpen((old) =>
                                old === row[subKey] ? "" : row[subKey]
                              ),
                            ...(renderProps ?? {}),
                          })}
                        </CustomTableCell>
                      );
                    })}
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {(page ?? null) !== null && (setPage ?? null) !== null && (
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"flex-end"}
            sx={{ mt: 1 }}
          >
            <Stack direction="row" spacing={2} alignItems={"center"}>
              <CustomIconButton
                type="back"
                onClick={() => setPage((old) => old - 1)}
                disabled={page <= 1}
              />
              <Typography variant="body2">
                {`${pageAmount * (page - 1) + 1} - ${
                  pageAmount * (page - 1) + data?.length
                } of ${count ?? -1}`}
              </Typography>
              <CustomIconButton
                type="next"
                onClick={() => setPage((old) => old + 1)}
                disabled={page * pageAmount >= count}
              />
            </Stack>
          </Stack>
        )}
        {children !== null && children}
      </Box>
    </Paper>
  );
}

export default CustomTable;

export const CustomHeadCell = ({ children, align }) => (
  <TableCell
    sx={{
      color: "#888888",
      py: "16px",
      pr: "16px",
      pl: "0",
      borderBottom: "unset",
      fontWeight: "400",
    }}
  >
    {children}
  </TableCell>
);

export const CustomTableCell = ({
  children,
  align,
  onClick,
  hasSubOpen,
  show,
  width,
}) => (
  <TableCell
    width={width ?? "unset"}
    sx={{
      backgroundColor: "#F7F8FC",
      borderBottom: "unset",
      height: show ? 80 : 0,
      transition: "all 200ms",
      "&:hover": {
        cursor: (onClick ?? null) === null ? "default" : "pointer",
      },
      "&:first-of-type": {
        borderTopLeftRadius: 12,
        borderBottomLeftRadius: hasSubOpen ? 0 : 12,
      },
      "&:last-child": {
        borderTopRightRadius: 12,
        borderBottomRightRadius: hasSubOpen ? 0 : 12,
      },
    }}
    align={align}
    onClick={onClick}
  >
    {children}
  </TableCell>
);

export const CustomTableSubCell = ({
  children,
  align,
  onClick,
  isLast,
  skip,
  width,
}) => (
  <TableCell
    width={width ?? "unset"}
    sx={{
      backgroundColor: "#F7F8FC",
      borderBottom: "unset",
      height: 80,
      //borderRight: "4px solid white",
      "&:hover": {
        cursor: (onClick ?? null) === null ? "default" : "pointer",
      },
      "&:first-of-type": {
        borderBottomLeftRadius: isLast ? 12 : 0,
      },
      "&:last-child": {
        borderBottomRightRadius: isLast ? 12 : 0,
        borderRight: "unset",
      },
    }}
    align={align}
    onClick={onClick}
  >
    {children}
  </TableCell>
);
