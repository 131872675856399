import { Stack } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SimpleText from "../../components/SimpleText";
import { CustomMenuButton } from "../../components/custom_components";
import { isFloat } from "../../utils/utils";

const styles = {
  trace_number: {},
  trace_cell: {
    display: "flex",
    width: 190,
    justifyContent: "space-between",
  },
};

const icon = (order) => (
  <ArrowUpwardIcon
    sx={{
      fontSize: "1.1rem",
      transition: "transform 200ms",
      transform: `rotate(${order === "desc" ? "180" : "0"}deg)`,
    }}
  />
);

export const SystemsColumns = [
  {
    label: "Acties",
    render: ({ row, chartData, showUserInfo, toggleHide, isAdmin }) => (
      <CustomMenuButton
        label="Acties"
        options={[
          {
            label: "Systeeminformatie",
            onClick: () => chartData(row.client_id, row),
          },
        ].concat(
          isAdmin
            ? [
                {
                  label: "Gebruikersinfo",
                  onClick: () => showUserInfo(row.client_id),
                },
                {
                  label: "Verberg systeem",
                  onClick: () =>
                    toggleHide(row.client_id, !(row.hide || false)),
                },
              ]
            : []
        )}
      />
    ),
  },
  {
    label: ({ sortClick, orderBy, order }) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        onClick={() => sortClick("registered")}
        sx={{ "&:hover": { cursor: "pointer" } }}
      >
        Geregistreerd
        {orderBy === "registered" ? icon(order) : <></>}
      </Stack>
    ),
    renderLabel: true,
    render: ({ row }) =>
      `${row.registered}`.includes("-")
        ? row.registered
        : new Date(row.registered * 1000).toLocaleDateString("fr-FR"),
  },
  {
    label: "Apparaat ID",
    render: ({ row }) => (
      <Stack>
        <SimpleText>{row.name}</SimpleText>
        <SimpleText>{row.client_id}</SimpleText>
      </Stack>
    ),
  },
  {
    label: "Installateur",
    render: ({ row }) => row?.installer,
  },
  {
    label: "Traceernummers",
    render: ({ row }) => (
      <>
        <div style={styles.trace_cell}>
          <span>Collector:</span>
          <span>{row.trace_number.collector || "-"}</span>
        </div>
        <div style={styles.trace_cell}>
          <span>Opslag:</span>
          <span>{row.trace_number.opslag || "-"}</span>
        </div>
        <div style={styles.trace_cell}>
          <span>Pomp:</span>
          <span>{row.trace_number.pomp || "-"}</span>
        </div>
      </>
    ),
  },
  {
    label: ({ sortClick, orderBy, order }) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        onClick={() => sortClick("city")}
        sx={{ "&:hover": { cursor: "pointer" } }}
      >
        Plaats
        {orderBy === "city" ? icon(order) : <></>}
      </Stack>
    ),
    renderLabel: true,
    render: ({ row }) => row?.city ?? "",
  },
  /*{
    label: ({ sortClick, orderBy, order }) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        onClick={() => sortClick("last_updated")}
        sx={{ "&:hover": { cursor: "pointer" } }}
      >
        Laatst geupdated
        {orderBy === "last_updated" ? icon(order) : <></>}
      </Stack>
    ),
    renderLabel: true,
    render: ({ row }) =>
      row.last_updated !== -1
        ? new Date(row.last_updated * 1000).toLocaleDateString("en-UK")
        : "-",
  },
  {
    label: ({ sortClick, orderBy, order }) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        onClick={() => sortClick("temp")}
        sx={{ "&:hover": { cursor: "pointer" } }}
      >
        Temperatuur
        {orderBy === "temp" ? icon(order) : <></>}
      </Stack>
    ),
    renderLabel: true,
    render: ({ row }) =>
      isFloat(row.temp) && row.temp >= 0 ? `${Math.round(row.temp)}°` : "N/A",
  },
  {
    label: "Laatst geupdated",
    render: ({ row }) =>
      row.last_updated !== -1
        ? new Date(row.last_updated * 1000).toLocaleDateString("en-UK")
        : "-",
  },*/
  {
    label: "Tempertuur",
    render: ({ row }) =>
      isFloat(row.temp) && row.temp >= 0 ? `${Math.round(row.temp)}°` : "N/A",
  },
];
