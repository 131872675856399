import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./App.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./Main";
import Login from "./pages/login/Login";
import NewAccount from "./pages/login/NewAccount";
import SystemInfo from "./pages/system_info/SystemInfo";
import Admin from "./pages/admin/Admin";
//import Installer from "./pages/installer/Installer";
import VerifyAccount from "./pages/login/VerifyAccount";
import Logout from "./pages/login/Logout";
import ReactNotification from "react-notifications-component";
import ForgotPassword from "./pages/login/ForgotPassword";
import ResetPassword from "./pages/login/ResetPassword";
import { ThemeProvider } from "@mui/styles";

import { light_theme } from "./assets/themes/light";
import AppContainer from "./containers/AppContainer";
import { ProSidebarProvider } from "react-pro-sidebar";
import InstallerOverview from "./pages/admin/components/installer_overview/InstallerOverview";
import ExternalOverview from "./pages/admin/components/external_overview/ExternalOverview";
import Monitoring from "./pages/monitoring";
import UnassignedOverview from "./pages/admin/components/unassigned_overview/UnassignedOverview";
import DataOverview from "./pages/admin/components/data_overview/DataOverview";
import ClientOverview from "./pages/admin/components/clients_overview/ClientOverview";
import UnlinkedSystems from "./pages/unlinked_systems/UnlinkedSystems";
import TestComponents from "./features/test_components/TestComponents";
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import Systems from "./features/systems/Systems";
import ClientsNew from "./features/clients_new/ClientsNew";
import { ConfirmationProvider } from "./contexts/ConfirmationContext";

function App() {
  const [queryClient] = React.useState(() => new QueryClient());

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ConfirmationProvider>
          <ThemeProvider theme={light_theme}>
            <ProSidebarProvider>
              <Routes>
                <Route element={<AppContainer />}>
                  <Route path="/" element={<Main />} />
                  <Route path="/login" element={<Login />} />
                  <Route
                    path="/wachtwoord-vergeten"
                    element={<ForgotPassword />}
                  />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/nieuw-account" element={<NewAccount />} />
                  <Route path="/verify_account" element={<VerifyAccount />} />
                  <Route path="/systeem-status" element={<SystemInfo />} />
                  <Route path="/admin">
                    <Route index element={<Admin />} />
                    <Route path="systemen">
                      <Route index element={<UnassignedOverview />} />
                      <Route path=":id" element={<DataOverview />} />
                    </Route>

                    <Route path="systemen-nieuw">
                      <Route index element={<Systems />} />
                      <Route path=":id" element={<DataOverview />} />
                    </Route>
                    <Route path="systemen" element={<UnassignedOverview />} />
                    <Route path="klanten" element={<ClientOverview />} />
                    <Route path="klanten-nieuw" element={<ClientsNew />} />
                    <Route
                      path="installateurs"
                      element={<InstallerOverview />}
                    />
                    <Route path="extern" element={<ExternalOverview />} />
                    <Route path="ongekoppeld" element={<UnlinkedSystems />} />
                    <Route path="prestaties" element={<Monitoring />} />
                    <Route
                      path="test-components"
                      element={<TestComponents />}
                    />
                  </Route>
                  <Route path="/installer">
                    <Route index element={<Systems />} />
                    <Route path="systemen-nieuw">
                      <Route index element={<Systems />} />
                      <Route path=":id" element={<DataOverview />} />
                    </Route>
                    <Route path="klanten-nieuw" element={<ClientsNew />} />
                  </Route>
                  <Route path="/external">
                    <Route index element={<Systems />} />
                    <Route path="systemen-nieuw">
                      <Route index element={<Systems />} />
                      <Route path=":id" element={<DataOverview />} />
                    </Route>
                    <Route path="klanten-nieuw" element={<ClientsNew />} />
                  </Route>
                  {/*<Route path="/installateur" element={<Installer />} />*/}
                  <Route path="/logout" element={<Logout />} />
                </Route>
              </Routes>
            </ProSidebarProvider>
          </ThemeProvider>
        </ConfirmationProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
